import React, { useState, useEffect } from 'react';

const Background = () => {
  const [currentImage, setCurrentImage] = useState(null);

  const imageList = [
    "./bg/lny_theme/1.webp",
    "./bg/lny_theme/2.webp",
    "./bg/lny_theme/3.webp",
    "./bg/lny_theme/4.webp",
    "./bg/lny_theme/5.webp",
    "./bg/lny_theme/6.webp",
  ];

  const getRandomImage = () => {
    const randomIndex = Math.floor(Math.random() * imageList.length);
    return imageList[randomIndex];
  };

  useEffect(() => {
    localStorage.removeItem('currentImage');
    const storedImage = localStorage.getItem('currentImage');
    if (storedImage) {
      setCurrentImage(storedImage);
    } else {
      const newImage = getRandomImage();
      setCurrentImage(newImage);
      localStorage.setItem('currentImage', newImage);
    }
  }, []);

  return (
    <div className="bg-container">
      <img
        src={currentImage}
        className="bg-main"
      />
    </div>
  );
};

export default Background;