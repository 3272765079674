import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './members.css'

const Members = (props) => {
  return (
    <div className={`members-container10 ${props.rootClassName} `}>
      <div className="members-container11">
        <div className="members-container12">
          <div className="members-container13">
            <span>
              {props.text2 ?? (
                <Fragment>
                  <span className="members-text168">
                    <span>Lớp trưởng</span>
                    <br></br>
                  </span>
                </Fragment>
              )}
            </span>
            <a
              href="https://guns.lol/qnyih"
              target="_blank"
              rel="noreferrer noopener"
              className="members-link10"
            >
              <div className="members-container14">
                <div className="members-container15">
                  <span className="members-text101">
                    {props.text4 ?? (
                      <Fragment>
                        <span className="members-text159">Quỳnh Nhi</span>
                      </Fragment>
                    )}
                  </span>
                </div>
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 24 24"
                  className="members-icon10"
                >
                  <path
                    d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6m4-3h6v6m-11 5L21 3"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
            </a>
          </div>
          <div className="members-container16">
            <span>
              {props.text3 ?? (
                <Fragment>
                  <span className="members-text161">Website owner</span>
                </Fragment>
              )}
            </span>
            <a
              href="https://longathelstan.xyz/"
              target="_blank"
              rel="noreferrer noopener"
              className="members-link12"
            >
              <div className="members-container21">
                <div className="members-container22">
                  <span className="members-text105">
                    {props.text41 ?? (
                      <Fragment>
                        <span className="members-text183">Thăng Long</span>
                      </Fragment>
                    )}
                  </span>
                </div>
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 24 24"
                  className="members-icon14"
                >
                  <path
                    d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6m4-3h6v6m-11 5L21 3"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
            </a>
          </div>
        </div>
        <div className="members-container23">
          <div className="members-container24">
            <span>
              {props.text22 ?? (
                <Fragment>
                  <span className="members-text171">
                    <span>Quàng Tử</span>
                    <br></br>
                  </span>
                </Fragment>
              )}
            </span>
            <a
              href="https://www.facebook.com/profile.php?id=100087907485086"
              target="_blank"
              rel="noreferrer noopener"
              className="members-link13"
            >
              <div className="members-container25">
                <div className="members-container26">
                  <span className="members-text107">
                    {props.text42 ?? (
                      <Fragment>
                        <span className="members-text187">
                          <span>Đức Anh</span>
                          <br></br>
                        </span>
                      </Fragment>
                    )}
                  </span>
                </div>
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 24 24"
                  className="members-icon16"
                >
                  <path
                    d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6m4-3h6v6m-11 5L21 3"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=100087801795292"
              target="_blank"
              rel="noreferrer noopener"
              className="members-link11"
            >
              <div className="members-container17">
                <div className="members-container18">
                  <span className="members-text103">
                    {props.text5 ?? (
                      <Fragment>
                        <span className="members-text235">Việt Anh</span>
                      </Fragment>
                    )}
                  </span>
                </div>
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 24 24"
                  className="members-icon12"
                >
                  <path
                    d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6m4-3h6v6m-11 5L21 3"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=100089624271014"
              target="_blank"
              rel="noreferrer noopener"
              className="members-link14"
            >
              <div className="members-container27">
                <div className="members-container28">
                  <span className="members-text108">
                    {props.text4211 ?? (
                      <Fragment>
                        <span className="members-text162">
                          <span>Lê Bảo</span>
                          <br></br>
                        </span>
                      </Fragment>
                    )}
                  </span>
                </div>
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 24 24"
                  className="members-icon18"
                >
                  <path
                    d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6m4-3h6v6m-11 5L21 3"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=100033463548041"
              target="_blank"
              rel="noreferrer noopener"
              className="members-link15"
            >
              <div className="members-container29">
                <div className="members-container30">
                  <span className="members-text109">
                    {props.text4210 ?? (
                      <Fragment>
                        <span className="members-text202">
                          <span>Tống Bảo</span>
                          <br></br>
                        </span>
                      </Fragment>
                    )}
                  </span>
                </div>
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 24 24"
                  className="members-icon20"
                >
                  <path
                    d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6m4-3h6v6m-11 5L21 3"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=100012665404786"
              target="_blank"
              rel="noreferrer noopener"
              className="members-link16"
            >
              <div className="members-container31">
                <div className="members-container32">
                  <span className="members-text110">
                    {props.text429 ?? (
                      <Fragment>
                        <span className="members-text174">
                          <span>Xuân Bình</span>
                          <br></br>
                        </span>
                      </Fragment>
                    )}
                  </span>
                </div>
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 24 24"
                  className="members-icon22"
                >
                  <path
                    d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6m4-3h6v6m-11 5L21 3"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=100093062757770"
              target="_blank"
              rel="noreferrer noopener"
              className="members-link17"
            >
              <div className="members-container33">
                <div className="members-container34">
                  <span className="members-text111">
                    {props.text428 ?? (
                      <Fragment>
                        <span className="members-text199">
                          <span>Lâm Dũng</span>
                          <br></br>
                        </span>
                      </Fragment>
                    )}
                  </span>
                </div>
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 24 24"
                  className="members-icon24"
                >
                  <path
                    d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6m4-3h6v6m-11 5L21 3"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=100092522056147"
              target="_blank"
              rel="noreferrer noopener"
              className="members-link18"
            >
              <div className="members-container35">
                <div className="members-container36">
                  <span className="members-text112">
                    {props.text427 ?? (
                      <Fragment>
                        <span className="members-text138">
                          <span>Anh Dũng</span>
                          <br></br>
                        </span>
                      </Fragment>
                    )}
                  </span>
                </div>
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 24 24"
                  className="members-icon26"
                >
                  <path
                    d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6m4-3h6v6m-11 5L21 3"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
            </a>
            <a
              href={props.containerUrl36}
              target="_blank"
              rel="noreferrer noopener"
              className="members-link19"
            >
              <div className="members-container37">
                <div className="members-container38">
                  <span className="members-text113">
                    {props.text426 ?? (
                      <Fragment>
                        <span className="members-text196">
                          <span>Tuấn Dũng</span>
                          <br></br>
                        </span>
                      </Fragment>
                    )}
                  </span>
                </div>
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 24 24"
                  className="members-icon28"
                >
                  <path
                    d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6m4-3h6v6m-11 5L21 3"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
            </a>
            <a
              href="https://duchuy.my.canva.site/"
              target="_blank"
              rel="noreferrer noopener"
              className="members-link20"
            >
              <div className="members-container39">
                <div className="members-container40">
                  <span className="members-text114">
                    {props.text425 ?? (
                      <Fragment>
                        <span className="members-text205">
                          <span>Đức Huy</span>
                          <br></br>
                        </span>
                      </Fragment>
                    )}
                  </span>
                </div>
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 24 24"
                  className="members-icon30"
                >
                  <path
                    d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6m4-3h6v6m-11 5L21 3"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=100094393785792"
              target="_blank"
              rel="noreferrer noopener"
              className="members-link21"
            >
              <div className="members-container41">
                <div className="members-container42">
                  <span className="members-text115">
                    {props.text424 ?? (
                      <Fragment>
                        <span className="members-text150">
                          <span>Quốc Khánh</span>
                          <br></br>
                        </span>
                      </Fragment>
                    )}
                  </span>
                </div>
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 24 24"
                  className="members-icon32"
                >
                  <path
                    d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6m4-3h6v6m-11 5L21 3"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=100082275716427"
              target="_blank"
              rel="noreferrer noopener"
              className="members-link22"
            >
              <div className="members-container43">
                <div className="members-container44">
                  <span className="members-text116">
                    {props.text423 ?? (
                      <Fragment>
                        <span className="members-text177">
                          <span>Bảo Nam</span>
                          <br></br>
                        </span>
                      </Fragment>
                    )}
                  </span>
                </div>
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 24 24"
                  className="members-icon34"
                >
                  <path
                    d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6m4-3h6v6m-11 5L21 3"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=100095052876030"
              target="_blank"
              rel="noreferrer noopener"
              className="members-link23"
            >
              <div className="members-container45">
                <div className="members-container46">
                  <span className="members-text117">
                    {props.text422 ?? (
                      <Fragment>
                        <span className="members-text226">
                          <span>Hoài Nam</span>
                          <br></br>
                        </span>
                      </Fragment>
                    )}
                  </span>
                </div>
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 24 24"
                  className="members-icon36"
                >
                  <path
                    d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6m4-3h6v6m-11 5L21 3"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=100083706210940"
              target="_blank"
              rel="noreferrer noopener"
              className="members-link24"
            >
              <div className="members-container47">
                <div className="members-container48">
                  <span className="members-text118">
                    {props.text421 ?? (
                      <Fragment>
                        <span className="members-text153">
                          <span>Vũ Nguyên</span>
                          <br></br>
                        </span>
                      </Fragment>
                    )}
                  </span>
                </div>
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 24 24"
                  className="members-icon38"
                >
                  <path
                    d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6m4-3h6v6m-11 5L21 3"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=100086275664280"
              target="_blank"
              rel="noreferrer noopener"
              className="members-link25"
            >
              <div className="members-container49">
                <div className="members-container50">
                  <span className="members-text119">
                    {props.text4212 ?? (
                      <Fragment>
                        <span className="members-text220">
                          <span>Chí Thành</span>
                          <br></br>
                        </span>
                      </Fragment>
                    )}
                  </span>
                </div>
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 24 24"
                  className="members-icon40"
                >
                  <path
                    d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6m4-3h6v6m-11 5L21 3"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=61554240063186"
              target="_blank"
              rel="noreferrer noopener"
              className="members-link26"
            >
              <div className="members-container51">
                <div className="members-container52">
                  <span className="members-text120">
                    {props.text4213 ?? (
                      <Fragment>
                        <span className="members-text165">
                          <span>Tuấn Việt</span>
                          <br></br>
                        </span>
                      </Fragment>
                    )}
                  </span>
                </div>
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 24 24"
                  className="members-icon42"
                >
                  <path
                    d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6m4-3h6v6m-11 5L21 3"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
            </a>
          </div>
          <div className="members-container53">
            <span>
              {props.text31 ?? (
                <Fragment>
                  <span className="members-text160">Công Túa</span>
                </Fragment>
              )}
            </span>
            <a
              href="https://www.facebook.com/profile.php?id=100075183973159&mibextid=ZbWKwL"
              target="_blank"
              rel="noreferrer noopener"
              className="members-link27"
            >
              <div className="members-container54">
                <div className="members-container55">
                  <span className="members-text122">
                    {props.text51 ?? (
                      <Fragment>
                        <span className="members-text211">
                          <span>Diệp Chi</span>
                          <br></br>
                        </span>
                      </Fragment>
                    )}
                  </span>
                </div>
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 24 24"
                  className="members-icon44"
                >
                  <path
                    d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6m4-3h6v6m-11 5L21 3"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=61565035449778"
              target="_blank"
              rel="noreferrer noopener"
              className="members-link28"
            >
              <div className="members-container56">
                <div className="members-container57">
                  <span className="members-text123">
                    {props.text511 ?? (
                      <Fragment>
                        <span className="members-text147">
                          <span>Thuỳ Dương</span>
                          <br></br>
                        </span>
                      </Fragment>
                    )}
                  </span>
                </div>
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 24 24"
                  className="members-icon46"
                >
                  <path
                    d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6m4-3h6v6m-11 5L21 3"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
            </a>
            <a
              href="https://bio.link/mgianggm"
              target="_blank"
              rel="noreferrer noopener"
              className="members-link29"
            >
              <div className="members-container58">
                <div className="members-container59">
                  <span className="members-text124">
                    {props.text512 ?? (
                      <Fragment>
                        <span className="members-text190">
                          <span>Minh Giang</span>
                          <br></br>
                        </span>
                      </Fragment>
                    )}
                  </span>
                </div>
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 24 24"
                  className="members-icon48"
                >
                  <path
                    d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6m4-3h6v6m-11 5L21 3"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=61562537310757"
              target="_blank"
              rel="noreferrer noopener"
              className="members-link30"
            >
              <div className="members-container60">
                <div className="members-container61">
                  <span className="members-text125">
                    {props.text513 ?? (
                      <Fragment>
                        <span className="members-text141">
                          <span>Thu Hiền</span>
                          <br></br>
                        </span>
                      </Fragment>
                    )}
                  </span>
                </div>
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 24 24"
                  className="members-icon50"
                >
                  <path
                    d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6m4-3h6v6m-11 5L21 3"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=61565436685072"
              target="_blank"
              rel="noreferrer noopener"
              className="members-link31"
            >
              <div className="members-container62">
                <div className="members-container63">
                  <span className="members-text126">
                    {props.text514 ?? (
                      <Fragment>
                        <span className="members-text144">
                          <span>Khánh Huyền</span>
                          <br></br>
                        </span>
                      </Fragment>
                    )}
                  </span>
                </div>
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 24 24"
                  className="members-icon52"
                >
                  <path
                    d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6m4-3h6v6m-11 5L21 3"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
            </a>
            <a
              href="https://bio.link/nghalinh15210"
              target="_blank"
              rel="noreferrer noopener"
              className="members-link33"
            >
              <div className="members-container66">
                <div className="members-container67">
                  <span className="members-text128">
                    {props.text516 ?? (
                      <Fragment>
                        <span className="members-text236">
                          <span>Hà Linh</span>
                          <br></br>
                        </span>
                      </Fragment>
                    )}
                  </span>
                </div>
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 24 24"
                  className="members-icon56"
                >
                  <path
                    d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6m4-3h6v6m-11 5L21 3"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=100085023645067"
              target="_blank"
              rel="noreferrer noopener"
              className="members-link32"
            >
              <div className="members-container64">
                <div className="members-container65">
                  <span className="members-text127">
                    {props.text515 ?? (
                      <Fragment>
                        <span className="members-text217">
                          <span>Bảo Thi</span>
                          <br></br>
                        </span>
                      </Fragment>
                    )}
                  </span>
                </div>
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 24 24"
                  className="members-icon54"
                >
                  <path
                    d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6m4-3h6v6m-11 5L21 3"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=100082245724082"
              target="_blank"
              rel="noreferrer noopener"
              className="members-link34"
            >
              <div className="members-container68">
                <div className="members-container69">
                  <span className="members-text129">
                    {props.text517 ?? (
                      <Fragment>
                        <span className="members-text193">
                          <span>Hoàng Linh</span>
                          <br></br>
                        </span>
                      </Fragment>
                    )}
                  </span>
                </div>
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 24 24"
                  className="members-icon58"
                >
                  <path
                    d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6m4-3h6v6m-11 5L21 3"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=100090649365032"
              target="_blank"
              rel="noreferrer noopener"
              className="members-link35"
            >
              <div className="members-container70">
                <div className="members-container71">
                  <span className="members-text130">
                    {props.text518 ?? (
                      <Fragment>
                        <span className="members-text239">
                          <span>Diệu Linh</span>
                          <br></br>
                        </span>
                      </Fragment>
                    )}
                  </span>
                </div>
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 24 24"
                  className="members-icon60"
                >
                  <path
                    d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6m4-3h6v6m-11 5L21 3"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=100078104345012&mibextid=LQQJ4d"
              target="_blank"
              rel="noreferrer noopener"
              className="members-link36"
            >
              <div className="members-container72">
                <div className="members-container73">
                  <span className="members-text131">
                    {props.text519 ?? (
                      <Fragment>
                        <span className="members-text156">
                          <span>Kim Ngân</span>
                          <br></br>
                        </span>
                      </Fragment>
                    )}
                  </span>
                </div>
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 24 24"
                  className="members-icon62"
                >
                  <path
                    d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6m4-3h6v6m-11 5L21 3"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
            </a>
            <a
              href="https://www.facebook.com/YunoDeyy"
              target="_blank"
              rel="noreferrer noopener"
              className="members-link37"
            >
              <div className="members-container74">
                <div className="members-container75">
                  <span className="members-text132">
                    {props.text5110 ?? (
                      <Fragment>
                        <span className="members-text229">
                          <span>Thảo Như</span>
                          <br></br>
                        </span>
                      </Fragment>
                    )}
                  </span>
                </div>
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 24 24"
                  className="members-icon64"
                >
                  <path
                    d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6m4-3h6v6m-11 5L21 3"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=100078172873518&mibextid=LQQJ4d"
              target="_blank"
              rel="noreferrer noopener"
              className="members-link38"
            >
              <div className="members-container76">
                <div className="members-container77">
                  <span className="members-text133">
                    {props.text5111 ?? (
                      <Fragment>
                        <span className="members-text223">
                          <span>Lan Phương</span>
                          <br></br>
                        </span>
                      </Fragment>
                    )}
                  </span>
                </div>
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 24 24"
                  className="members-icon66"
                >
                  <path
                    d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6m4-3h6v6m-11 5L21 3"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
            </a>
            <a
              href="https://bio.link/dkrhq2xy"
              target="_blank"
              rel="noreferrer noopener"
              className="members-link39"
            >
              <div className="members-container78">
                <div className="members-container79">
                  <span className="members-text134">
                    {props.text5112 ?? (
                      <Fragment>
                        <span className="members-text184">
                          <span>Mai Quyên</span>
                          <br></br>
                        </span>
                      </Fragment>
                    )}
                  </span>
                </div>
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 24 24"
                  className="members-icon68"
                >
                  <path
                    d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6m4-3h6v6m-11 5L21 3"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=100086672336999"
              target="_blank"
              rel="noreferrer noopener"
              className="members-link40"
            >
              <div className="members-container80">
                <div className="members-container81">
                  <span className="members-text135">
                    {props.text5113 ?? (
                      <Fragment>
                        <span className="members-text180">
                          <span>Huyền Tâm</span>
                          <br></br>
                        </span>
                      </Fragment>
                    )}
                  </span>
                </div>
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 24 24"
                  className="members-icon70"
                >
                  <path
                    d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6m4-3h6v6m-11 5L21 3"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
            </a>
            <a
              href="https://bio.link/hngthmng"
              target="_blank"
              rel="noreferrer noopener"
              className="members-link41"
            >
              <div className="members-container82">
                <div className="members-container83">
                  <span className="members-text136">
                    {props.text5114 ?? (
                      <Fragment>
                        <span className="members-text214">
                          <span>Hồng Thắm</span>
                          <br></br>
                        </span>
                      </Fragment>
                    )}
                  </span>
                </div>
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 24 24"
                  className="members-icon72"
                >
                  <path
                    d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6m4-3h6v6m-11 5L21 3"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=100087297836900&mibextid=ZbWKwL"
              target="_blank"
              rel="noreferrer noopener"
              className="members-link42"
            >
              <div className="members-container84">
                <div className="members-container85">
                  <span className="members-text137">
                    {props.text5115 ?? (
                      <Fragment>
                        <span className="members-text232">
                          <span>Khánh Linh</span>
                          <br></br>
                        </span>
                      </Fragment>
                    )}
                  </span>
                </div>
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 24 24"
                  className="members-icon74"
                >
                  <path
                    d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6m4-3h6v6m-11 5L21 3"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
            </a>
            <a
              href="https://bio.link/vytran172"
              target="_blank"
              rel="noreferrer noopener"
              className="members-link42"
            >
              <div className="members-container84">
                <div className="members-container85">
                  <span className="members-text137">
                    {props.text5116 ?? (
                      <Fragment>
                        <span className="members-text232">
                          <span>Thảo Vy</span>
                          <br></br>
                        </span>
                      </Fragment>
                    )}
                  </span>
                </div>
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 24 24"
                  className="members-icon74"
                >
                  <path
                    d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6m4-3h6v6m-11 5L21 3"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

Members.defaultProps = {
  containerUrl116: 'https://www.instagram.com/d_k2125.nb/',
  containerUrl37: 'https://www.instagram.com/d_k2125.nb/',
  text427: undefined,
  containerUrl33: 'https://www.instagram.com/d_k2125.nb/',
  containerUrl1114: 'https://www.instagram.com/d_k2125.nb/',
  text513: undefined,
  text514: undefined,
  text511: undefined,
  text424: undefined,
  text421: undefined,
  containerUrl113: 'https://www.instagram.com/d_k2125.nb/',
  containerUrl313: 'https://www.instagram.com/d_k2125.nb/',
  containerUrl111: 'https://www.instagram.com/d_k2125.nb/',
  containerUrl39: 'https://www.instagram.com/d_k2125.nb/',
  text519: undefined,
  text4: undefined,
  text31: undefined,
  text3: undefined,
  text4211: undefined,
  containerUrl31: 'https://www.instagram.com/d_k2125.nb/',
  text4213: undefined,
  text2: undefined,
  text22: undefined,
  containerUrl35: 'https://www.instagram.com/d_k2125.nb/',
  containerUrl1113: 'https://www.instagram.com/d_k2125.nb/',
  text429: undefined,
  text423: undefined,
  containerUrl38: 'https://www.instagram.com/d_k2125.nb/',
  containerUrl118: 'https://www.instagram.com/d_k2125.nb/',
  text5113: undefined,
  containerUrl115: 'https://www.instagram.com/d_k2125.nb/',
  text41: undefined,
  containerUrl1110: 'https://www.instagram.com/d_k2125.nb/',
  containerUrl36: 'https://www.instagram.com/d_k2125.nb/',
  rootClassName: '',
  text5112: undefined,
  text42: undefined,
  text512: undefined,
  containerUrl11: 'https://www.instagram.com/d_k2125.nb/',
  containerUrl112: 'https://www.instagram.com/d_k2125.nb/',
  containerUrl34: 'https://www.instagram.com/d_k2125.nb/',
  containerUrl311: 'https://www.instagram.com/d_k2125.nb/',
  text517: undefined,
  containerUrl: 'https://www.instagram.com/d_k2125.nb/',
  text426: undefined,
  text428: undefined,
  containerUrl1115: 'https://www.instagram.com/d_k2125.nb/',
  containerUrl1111: 'https://www.instagram.com/d_k2125.nb/',
  text4210: undefined,
  text425: undefined,
  text21: undefined,
  text51: undefined,
  text5114: undefined,
  text515: undefined,
  containerUrl119: 'https://www.instagram.com/d_k2125.nb/',
  text4212: undefined,
  containerUrl3: 'https://www.instagram.com/d_k2125.nb/',
  text5111: undefined,
  text422: undefined,
  text5110: undefined,
  containerUrl117: 'https://www.instagram.com/d_k2125.nb/',
  text5115: undefined,
  text5: undefined,
  text516: undefined,
  containerUrl114: 'https://www.instagram.com/d_k2125.nb/',
  containerUrl310: 'https://www.instagram.com/d_k2125.nb/',
  containerUrl32: 'https://www.instagram.com/d_k2125.nb/',
  containerUrl312: 'https://www.instagram.com/d_k2125.nb/',
  text518: undefined,
}

Members.propTypes = {
  containerUrl116: PropTypes.string,
  containerUrl37: PropTypes.string,
  text427: PropTypes.element,
  containerUrl33: PropTypes.string,
  containerUrl1114: PropTypes.string,
  text513: PropTypes.element,
  text514: PropTypes.element,
  text511: PropTypes.element,
  text424: PropTypes.element,
  text421: PropTypes.element,
  containerUrl113: PropTypes.string,
  containerUrl313: PropTypes.string,
  containerUrl111: PropTypes.string,
  containerUrl39: PropTypes.string,
  text519: PropTypes.element,
  text4: PropTypes.element,
  text31: PropTypes.element,
  text3: PropTypes.element,
  text4211: PropTypes.element,
  containerUrl31: PropTypes.string,
  text4213: PropTypes.element,
  text2: PropTypes.element,
  text22: PropTypes.element,
  containerUrl35: PropTypes.string,
  containerUrl1113: PropTypes.string,
  text429: PropTypes.element,
  text423: PropTypes.element,
  containerUrl38: PropTypes.string,
  containerUrl118: PropTypes.string,
  text5113: PropTypes.element,
  containerUrl115: PropTypes.string,
  text41: PropTypes.element,
  containerUrl1110: PropTypes.string,
  containerUrl36: PropTypes.string,
  rootClassName: PropTypes.string,
  text5112: PropTypes.element,
  text42: PropTypes.element,
  text512: PropTypes.element,
  containerUrl11: PropTypes.string,
  containerUrl112: PropTypes.string,
  containerUrl34: PropTypes.string,
  containerUrl311: PropTypes.string,
  text517: PropTypes.element,
  containerUrl: PropTypes.string,
  text426: PropTypes.element,
  text428: PropTypes.element,
  containerUrl1115: PropTypes.string,
  containerUrl1111: PropTypes.string,
  text4210: PropTypes.element,
  text425: PropTypes.element,
  text21: PropTypes.element,
  text51: PropTypes.element,
  text5114: PropTypes.element,
  text515: PropTypes.element,
  containerUrl119: PropTypes.string,
  text4212: PropTypes.element,
  containerUrl3: PropTypes.string,
  text5111: PropTypes.element,
  text422: PropTypes.element,
  text5110: PropTypes.element,
  containerUrl117: PropTypes.string,
  text5115: PropTypes.element,
  text5: PropTypes.element,
  text516: PropTypes.element,
  containerUrl114: PropTypes.string,
  containerUrl310: PropTypes.string,
  containerUrl32: PropTypes.string,
  containerUrl312: PropTypes.string,
  text518: PropTypes.element,
}

export default Members
